import React/*, { useState, useEffect }*/ from 'react';
import './styles.scss';

import Logo from '../../components/Logo';
// import { Link } from 'react-router-dom';

// import { getListaLinkRestaurantes } from '../../database/repository';

export default _ => {
  // const [path, setPath] = useState('');
  // const [links, setLinks] = useState([]);

  // useEffect(() => {
  //   async function fetchLinks() {
  //     return await getListaLinkRestaurantes();
  //   }
  //   fetchLinks().then(restaurantesLinks => {
  //     setLinks(restaurantesLinks);
  //   });
  // }, []);

  return (
    <div className='home-container'>
      <div className='link-to-survey-container'>
        <h4>Circuito Gastronômico da Pampulha</h4>
        <h5>Votações encerradas.</h5>
        {/* <div className='action-container'>
          <div>
            <h6>Escolha o restuarante e avalie o prato consumido:</h6>
            <select 
              className='form-control'
              onChange={e => setPath(e.target.value)}
            >
              <option value=''>Escolher restaurante:</option>
              {links.map(link => (
                <option key={link.link} value={link.link}>{link.name}</option>
              ))}
            </select>
          </div>
          <Link
            to={`/${path}`}
            className={`btn btn-primary ${!path.length ? 'disable-link' : ''}`}
          >
            Avaliar Prato
          </Link>
        </div> */}

      </div>
      <div className='thanks-section'>
        <h6>
          Deselvolvido por:
          <span style={{ paddingLeft: '10px' }} > 
            <Logo
              height={50}
              asset='logo-responda-aqui-preta-nav'
            />
          </span>
        </h6>
        
        <a 
          className='site-link'
          href='http://www.respondaaqui.com.br'
          target='_blank'
          rel="noopener noreferrer"
        >
          <i>Clique aqui</i>
        </a>
        <span className='site-link-text'>para nos conhecer</span>
      </div>
    </div>
  );
}