import React from 'react';

export default ({
  height,
  asset
}) => (
  <>
    <img 
      src={require(`../../assets/${asset}.png`)}
      alt="logo"
      style={{ height }}
    />
  </>
);
