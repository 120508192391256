import { firebaseDatabase } from './config';
import { api } from './api';

const constantsRef = firebaseDatabase.ref('constants');
const answersRef = firebaseDatabase.ref('answers');

export const getRestauranteList = async restauranteName => {
  return constantsRef.child(`lista-restaurantes-pratos/${restauranteName}`).once('value').then(snapshot => snapshot.val())
};

export const getListaLinkRestaurantes = async _ => {
  return constantsRef.child('lista-link-restaurantes').once('value').then(snapshot => snapshot.val())
};

export const pushAnswer = async data =>{
  try {
    await answersRef.push(data);
  } catch (error) {
    console.log(error);
  }
};

export const checkUsedMailCloudFunction = (reatauranteId, email) => {
  return new Promise(async (resolve, reject) => {
    api.post('checkUsedMail', { 
      data: {
        reatauranteId,
        email
      }
    }
    ).then(result => {
      resolve(result.data);
    }).catch(err => {
      reject(err);
    });
  })
}
