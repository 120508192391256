import React from 'react';
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import Survey from './containers/Survey';
import Home from './containers/Home'

export default _ => (
  <Switch>
    <Route exact path='/' component={Home} />
    <Redirect push to='/' />
  </Switch>
);
